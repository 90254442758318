import {SERVER} from "./globals";

export const createPaymentRequest = async (firstName, lastName, email, phoneNumber, amount, urlParameters, _type_, onSuccess=null, OnFailure=null, applePay=false) => {

    var headers = new Headers();

    headers.append("Authorization", `Token ${process.env.REACT_APP_TOKEN}`);

    var body = new FormData();

    let server = SERVER;
    
    body.append("FirstName", firstName);
    body.append("LastName", lastName);
    body.append("Amount", amount);
    body.append("Phone", phoneNumber);
    body.append("Email", email);

    if(_type_ === "europe"){
        server = `${server}createNewPaymentEurope/`;
        body.append("iddevice", urlParameters.iddevice);
    }else if(_type_ === "chinese_multi"){
        server = `${server}createNewPaymentChineseMultiple/`;
        body.append("mid", urlParameters.mid);
        body.append("slotinfo", urlParameters.slotinfo);
    }else if(_type_ === "chinese_single"){
        server = `${server}createNewPaymentChineseSingle/`;
        body.append("mid", urlParameters.mid);
        body.append("sid", urlParameters.sid);
        body.append("pid", urlParameters.pid);
        body.append("pri", urlParameters.pri);
    }

    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: headers,
        body: body
    }

    const response = await fetch(`${server}`, requestOptions);
    
    if (!response.ok) {
        const result = await response.json();
        if(applePay && OnFailure){
            OnFailure();
        }
        throw new Error(result["detail"]);
    }else{
        if(applePay === true && onSuccess !== null){
            const result = await response.json();
            // console.log("resultObj", result);
            onSuccess(result["resultObj"]["id"]);
        }
    }


    return response.json();
}