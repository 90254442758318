import React from 'react';
import mainStyles from "../Stylesheets/main";


const Footer = (props) => {
    return (
        <div style={mainStyles.containerFooter}>
            <img src="static/images/poweredBySkipCash.png" width={144} height={72} alt="poweredBySkipCash" />
        </div>
    )
}

export default Footer;