export const skipcash = {
  sdk: {
    defaults: {
      btnInnerHtml: "Pay with ",
      btnHmlAfterLogo: "SkipCash",
      sandBoxUrl: "https://skipcashtest.azurewebsites.net/",
      productionUrl: "https://api.skipcash.app/",
      // environment: "sandbox",
      environment: "production",

      onError: function (message) {
        // console.log(message);
      },
      direct: false,
    },

    /**
     * /
     * @param {any} options
     * sandbox : bool - true for TEST env, false for PROD env
     * @param {any} onSuccess
     * @param {any} onError
     */
    canPayWithApple: function (merchantIdentifier, onSuccess, onError) {
      if (!window.ApplePaySession) {
        if (onError != null) onError(1);
        return;
      }
      
      if (!window.ApplePaySession.canMakePayments()) {
        if (onError != null) onError(2);
        return;
      }

      var promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          merchantIdentifier
        );

      promise.then(function (canMakePayments) {
        if (canMakePayments) {
          if (onSuccess != null) onSuccess();
        } else {
          if (onError != null) onError(3);
        }
      });
    },

    /**
     * /
     * @param {any} options
     * amount : number - the amount on the transaction
     * paymentId : string - the skipcash transaction ID, options, if onCreatePayment was passed
     * sandbox : bool - true: TEST environment, false: PROD environment
     * server : string - optional, can overwrite the skipcash server URL set by sandbox value
     * merchant : string - optional, merchant name, if not set, SkipCash
     * onCreatePayment : function to generate a new payment, if paymentId was not supplied
     * onValidateSession : validate call to apple
     */

    payWithApplePay: function (options, onSuccess, onError) {
      var skipcashServer = options.sandbox
        ? "https://skipcashtest.azurewebsites.net"
        : "https://api.skipcash.app";

      // console.log("skipcashServer ---> ", skipcashServer);

      var merchantName = options.merchant ? options.merchant : "SkipCash";

      var PAYMENT_ID = "";

      // console.log("merchantName ---> ", merchantName);

      var request = {
        countryCode: "QA",
        currencyCode: "QAR",
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        total: { label: merchantName, amount: options.amount },
      };
      // console.log("request ---> ", request);

      var session = new ApplePaySession(12, request);
      // console.log("session ---> ", session);

      session.begin();
      session.onvalidatemerchant = function (event) {
        var url = event.validationURL;
        // console.log("validation url ---> ", url);

        const machineType = options.getVendingMachineType(options.urlParameters);

        const onSuccessfulPaymentCreation = (payment_id) => {

          PAYMENT_ID = payment_id;
          // console.log("paymentId ---> ", PAYMENT_ID);

          var promise = options.onValidateSession(
            url, (data)=>{
              // console.log("validation url was generated ---> ", data);
            }, (data)=>{
              // console.log("validation url generation failed ---> ", data);
            }
          );
  
          promise.then(function (merchantSession) {
            session.completeMerchantValidation(merchantSession);
          });
        };

        const onFailedPaymentCreation = (error) => {
          // console.log("failed to create a new payment!");
          onError();
        }

        options.createPaymentRequest(
          options.firstName,
          options.lastName,
          options.email,
          options.phoneNumber,
          options.amount,
          options.urlParameters,
          machineType,
          onSuccessfulPaymentCreation,
          onFailedPaymentCreation,
          true
        );
        
      };

      session.onpaymentauthorized = (e) => {
        var applePaymentToken = e.payment.token.paymentData;
        callApplePay(
          PAYMENT_ID,
          skipcashServer,
          applePaymentToken,
          function () {
            session.completePayment(ApplePaySession.STATUS_FAILURE);
            if (onError) {
              // console.log("onpaymentauthorized error time", applePaymentToken);
              onError("Apple pay failed!");
            }
          },
          function () {
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
            if (onSuccess) {
              onSuccess(`${skipcashServer}/pay/${PAYMENT_ID}/receipt`);
            }
          },
          session
        );
      };
    },
  },
};

function callApplePay(
  paymentId,
  server,
  applePaymentToken,
  onError,
  onSuccess,
  session
) {
  var request = {
    paymentId: paymentId,
    token: applePaymentToken,
  };

  var req = new XMLHttpRequest();

  req.onload = function () {
    try{
      const response = JSON.parse(req.responseText);
      // console.log(response);
      if (response.resultObj) {
        if(response.resultObj.isSuccess === true) {
          onSuccess();
        } else {
          onError();
        }
      }else{
        session.completePayment(ApplePaySession.STATUS_FAILURE);
        onError();
      }
    }catch(e){
      session.completePayment(ApplePaySession.STATUS_FAILURE);
      onError(e);
    }
  };

  req.open(
    "POST",
    `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/api/payApplePay/`,
    true
  );

  req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  req.setRequestHeader("Authorization", `Token ${process.env.REACT_APP_TOKEN}`);
  
  req.send(JSON.stringify(request));
}