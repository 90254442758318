import React, { useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive'
import {message, Modal, Button, Space, Tooltip} from 'antd';
import { CheckOutlined, AppleFilled  } from '@ant-design/icons';
import mainStyles from './Stylesheets/main';
import Header from './Components/Header';
import LangChanger from './Components/LangChanger';
import DataForm from './Components/Form';
import Footer from './Components/Footer';
import {createPaymentRequest} from './actions';
import { getLanguages } from './globals';

import {skipcash} from './sdk';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';

function App() {

  const isDesktop       = useMediaQuery({ minWidth: 992 });
  const isMobile        = useMediaQuery({ maxWidth: 767 });
  const isSmallMobile   = useMediaQuery({ maxWidth: 375 });
  const isTablet        = useMediaQuery({ minWidth: 577, maxWidth: 1224 });

  const [languageCode, setLanguageCode]   = useState("en");
  const [firstName, setFirstName]         = useState("John");
  const [lastName, setLastName]           = useState("Doe");
  const [email, setEmail]                 = useState("");
  const [amount, setAmount]               = useState("");
  const [phoneNumber, setPhoneNumber]     = useState("");
  const [ApplePayAvailable, setApplePayAvailable] = useState(null);
    

  const [submitLoadingRequest, setSubmitLoadingRequest] = useState(false);

  const [payOptionsModal, setPayOptionsModal] = useState(false);

  const [paymentIdState, setPaymentIdState]   = useState("");

  const [payUrl, setPayUrl]                   = useState("");
    
  const onSuccess = () => {
    // console.log("onSuccess ran!");
    setApplePayAvailable(true);
    // if(ApplePayAvailable === null){
    // }
  }

  const onError = (error) => {
    // console.log(`error ran! error num -> ${error}`);
    setApplePayAvailable(false);
    // if(ApplePayAvailable === null){
    // }
  }

  const isApplePayAvailable = () => {
    skipcash.sdk.canPayWithApple(
      process.env.REACT_APP_MERCHANT_ID,
      onSuccess,
      onError
    )
  }

  useEffect(()=>{
      // to be called once.
      isApplePayAvailable();
  }, []);

  const getVendingMachineType = (urlParameters) => {
    if(urlParameters.iddevice !== null){
      return "europe";
    }else if (urlParameters.mid !== null && urlParameters.sid !== null && urlParameters.pid !== null && urlParameters.pri !== null) {
      return "chinese_single";
    }else if (urlParameters.mid !== null && urlParameters.soltinfo !== null) {
      return "chinese_multi";
    }

    return "cannot_identify_the_machine";
  }

  const LoadingIndicator = () => {

    if (!submitLoadingRequest){return null;}

    return (
      <div className='w-full flex justify-center items-center h-screen'>
        <DotLottieReact
          src={"static/images/animation.lottie"}
          style={{width: "50%", height: "50%"}}
          loop
          autoplay
        />
      </div>
    );
  };


  const getPaymentLink = async (firstName, lastName, email, phoneNumber, amount, urlParameters) => {

    // check if eruop machine || chinese single || chinese multi 
    const _type_ = getVendingMachineType(urlParameters);

    setSubmitLoadingRequest(false);

    if(_type_ !== null && _type_ !== "cannot_identify_the_machine"){
      try{
        let result           = await createPaymentRequest(firstName, lastName, email, phoneNumber, amount, urlParameters, _type_);
        return result["resultObj"];
      }catch(e){
        return null;
      }
    }

    return "cannot_identify_the_machine";
  }

  const onApplePaySuccess = (receipt) => {
    message.success(getLanguages()[languageCode]["successfulPayment"], 4);
    // let temp_pay_url = payUrl;
    // setPayUrl("");
    // setPaymentIdState("");
    // setPayOptionsModal(false);
    window.location.href = receipt;
  };
  
  const onApplePayFailure = (data) => {
    message.error("Payment Failed!");
  };

  function validateSession(valURL, onValidateSuccess, onValidateError) {
  
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();

      xhr.open(
        "POST",
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/api/appleUrlValidation/`
      );
  
      xhr.setRequestHeader("Content-Type", "application/json");

      const body = {
        validation_url: valURL,
      };
  
      xhr.onload = function () {
        if (xhr.status === 200) {
          var data = JSON.parse(this.responseText);
          // console.log("apple validation response: ", data);
          onValidateSuccess(data);
          resolve(data);
        } else {
          var data = JSON.parse(this.responseText);
          // console.error("Request failed with status:", xhr.status);
          onValidateError(data);
          reject(data);
        }
      };
  
      xhr.onerror = function () {
        // console.error("Network error occurred");
        onValidateError("Network error");
        reject("Network error");
      };
  

      xhr.send(JSON.stringify(body));
    });
  }

  const handleApplePayButton = (firstName, lastName, email, phoneNumber, amount, urlParameters) => {
    try{

        /**
            * /
            * @param {any} options
            * amount : number - the amount on the transaction
            * paymentId : string - the skipcash transaction ID, options, if onCreatePayment was passed
            * sandbox : bool - true: TEST environment, false: PROD environment
            * server : string - optional, can overwrite the skipcash server URL set by sandbox value
            * merchant : string - optional, merchant name, if not set, SkipCash
            * onCreatePayment : function to generate a new payment, if paymentId was not supplied
            * onValidateSession : validate call to apple
        */

        const options = {
          amount: parseFloat(amount),
          sandbox : false,
          merchant: "Vendex",
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          urlParameters: urlParameters,
          onValidateSession: validateSession,
          getVendingMachineType: getVendingMachineType,
          createPaymentRequest: createPaymentRequest
        };

        skipcash.sdk.payWithApplePay(options, onApplePaySuccess, onApplePayFailure);

    }catch(e){
        // console.log(e)
        message.error(getLanguages()[languageCode]["apple_failed_to_initiate_payment"]);
    }
  }
  
  const Body = () => (
    <div 
      // className="min-w-[992px] flex h-[90vh] m-0"
      style={mainStyles.bodyStyle}
    >
      <LangChanger languageCode={languageCode} setLanguageCode={setLanguageCode} />
      <Header languageCode={languageCode} />
      <DataForm
        setPayUrl={setPayUrl}
        setPaymentIdState={setPaymentIdState}
        setPayOptionsModal={setPayOptionsModal}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setAmount={setAmount}
        setPhoneNumber={setPhoneNumber}
        getPaymentId={getPaymentLink}
        // handleSubmit={handleSubmit}
        handleApplePayButton={handleApplePayButton}
        firstName={firstName}
        lastName={lastName}
        email={email}
        amount={amount}
        phoneNumber={phoneNumber}
        languageCode={languageCode} 
        ApplePayAvailable={ApplePayAvailable}
        setLoadingIndicator={setSubmitLoadingRequest}
        // setAppleVPNTooleTipVisibility={setAppleVPNTooleTipVisibility}
      />
      <Footer />  
    </div>
  )

  if(isDesktop) {
    return (
        <>
          {
            submitLoadingRequest ? 
              <LoadingIndicator />
            :
              <div style={mainStyles.desktopScreen}>
                <div style={mainStyles.desktopContainer}>
                  <Body />
                </div>
              </div>
          }
        </>
      )
  }else if(isTablet) {
    return(
      <>
        {
          submitLoadingRequest ? 
            <LoadingIndicator />
          :
            <div style={{minWidth: 577, maxWidth: 1224}}>
              <div style={mainStyles.mobileTabletScreens}>
                <div style={mainStyles.mobileTabletsContainer}>
                  <Body />
                </div>
              </div>
            </div>
        }
      </>
    )
  } else if(isSmallMobile){
      return (
        <>
         {
          submitLoadingRequest ? 
            <LoadingIndicator />
          :
            <div style={{maxWidth: 350, width: "150vh", height: "90vh"}}>
              <div style={mainStyles.mobileTabletScreens}> 
                <div style={mainStyles.mobileTabletsContainer}>
                  <Body />
                </div>
              </div>
            </div>          
          }
        </>
      )
  } else if(isMobile) {
    return (
      <>
       {
          submitLoadingRequest ? 
            <LoadingIndicator />
          :
            <div style={{maxWidth: 767}}>
              <div style={mainStyles.mobileTabletScreens}> 
                <div style={mainStyles.mobileTabletsContainer}>
                  <Body />
                </div>
              </div>
            </div>
        }
      </>
    )
  } 

    
}

export default App;
