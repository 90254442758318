import React, {useRef, useEffect, forwardRef, useImperativeHandle, useState} from 'react';
import { getLanguages, countries, convertToArabicNumerals } from '../globals';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

const PhoneNumber = forwardRef((props, ref) => {

    const FloatingWindowRef         = useRef(null);
    const CountryCodeBtnRef         = useRef(null);
    const FlagIconElement           = useRef(null);
    const CountryCodeElement        = useRef(null);
    const DropIconElement           = useRef(null);
    const CountriesListElement      = useRef(null);
    const PhoneNumberInputElement   = useRef(null);
    const PhoneOuterDiv             = useRef(null);
    const errorMarkDiv              = useRef(null);

    let CountriesListElementRoot             = null;

    let countriesListFlag                    = true;


    const toggleFloatingWindow = () => {
        if (FloatingWindowRef.current) {

            let containsHidden = false;

            FloatingWindowRef.current.classList.toggle('hidden');

            Object.entries(FloatingWindowRef.current.classList).forEach((item)=>{
                if(item[1] === 'hidden'){
                    containsHidden = true;
                }
            });

            if(containsHidden === false) {
                DropIconElement.current.src = "static/images/icons/dropup.svg";
            }else{
                DropIconElement.current.src = "static/images/icons/dropdown.svg";
            }

        }
    }
    
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (FloatingWindowRef.current && !FloatingWindowRef.current.contains(event.target)) {
            if(! CountryCodeBtnRef.current.contains(event.target)){
                let containsHidden = false;
    
                Object.entries(FloatingWindowRef.current.classList).forEach((item)=>{
                    if(item[1] === 'hidden'){
                        containsHidden = true;
                    }
                });
    
                if(containsHidden === false){
                    FloatingWindowRef.current.classList.add('hidden');
                    DropIconElement.current.src = "static/images/icons/dropdown.svg";
                }
            }
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        // Cleanup the event listener when the component is unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [toggleFloatingWindow]);


    useImperativeHandle(ref, () => ({

        showPhoneNumberError(){

            if(errorMarkDiv.current.classList.contains('hidden')){
                errorMarkDiv.current.classList.remove('hidden');
            }

            if(PhoneOuterDiv.current.classList.contains("border-gray-300")){
                PhoneOuterDiv.current.classList.remove('border-gray-300');
                PhoneOuterDiv.current.classList.add('border-errorColor-200');
                PhoneOuterDiv.current.classList.add('bg-errorColor-100');
                CountryCodeBtnRef.current.classList.remove('border-gray-300');
                CountryCodeBtnRef.current.classList.add('border-errorColor-200');
                CountryCodeBtnRef.current.classList.add('bg-errorColor-100');
                PhoneNumberInputElement.current.classList.add('bg-errorColor-100');
            }
        },
    
        hidePhoneNumberError(){

            if(PhoneOuterDiv.current.classList.contains("border-errorColor-200")){
                PhoneOuterDiv.current.classList.add('border-gray-300');
                PhoneOuterDiv.current.classList.remove('border-errorColor-200');
                PhoneOuterDiv.current.classList.remove('bg-errorColor-100');
                CountryCodeBtnRef.current.classList.add('border-gray-300');
                CountryCodeBtnRef.current.classList.remove('border-errorColor-200');
                CountryCodeBtnRef.current.classList.remove('bg-errorColor-100');
                PhoneNumberInputElement.current.classList.remove('bg-errorColor-100');
            }

            if(!errorMarkDiv.current.classList.contains('hidden')){
                errorMarkDiv.current.classList.add('hidden');
            }
        }
    
    }));


    const getCountriesList = (searchCountry="") => {
        let tempCountriesList = null;

        if(searchCountry.length > 0) {
            tempCountriesList = Object.entries(countries).filter((country) => country[1].langCode[props.languageCode].toLowerCase().includes(searchCountry.toLowerCase()) || country[1].code.includes(searchCountry.toLowerCase()))
        }else{
            tempCountriesList = Object.entries(countries);
        }


        const countriesResult =  tempCountriesList.map((country, key) => (
            <li 
                dir={props.languageCode === 'ar'? 'rtl' : 'ltr'} key={key}
                className="px-4 flex items-center cursor-pointer hover:bg-gray-100"
                style={{paddingTop: "13px", paddingBottom: "13px"}}
                onClick={()=>{
                    if(props.languageCode === 'ar'){
                        CountryCodeElement.current.innerHTML = `${convertToArabicNumerals(country[1].code)}`;
                        CountryCodeElement.current.code      = `${country[1].code}`;
                    }else{
                        CountryCodeElement.current.innerHTML = `${country[1].code}`;
                        CountryCodeElement.current.code      = `${country[1].code}`;
                    }
                    FlagIconElement.current.src = `static/images/icons/flags/${country[1].icon}`;
                    toggleFloatingWindow();
                }}
            >
                <img
                    src={`static/images/icons/flags/${country[1].icon}`}
                    alt={`${country[1].langCode[props.languageCode]}`}
                    className="shadow-[0_4px_6px_rgba(0,0,0,0.16)]"
                />
                <div>
                    <span
                        style={{
                            paddingLeft: props.languageCode === 'en'? '16px' : '0px',
                            paddingRight: props.languageCode === 'en'? '0px' : '16px'
                        }}
                        className='mr-1 ml-1 text-[#27364B] text-[16px] leading-[24px] text-center' dir={props.languageCode === "ar" ? "rtl" : "ltr"}>
                        {country[1].langCode[props.languageCode]}
                    </span>
                    <span
                         dir={"ltr"} className='text-[#64748B] text-[16px] leading-[24px] text-center'    
                         style={{
                            paddingLeft: props.languageCode === 'en'? '8px' : '0px',
                            paddingRight: props.languageCode === 'en'? '0px' : '8px'
                        }}
                    >
                       {
                            `(${country[1].code})`
                        } 
                    </span>
                </div>
            </li>
        ))

        try{
            CountriesListElementRoot.render(countriesResult);
        }catch(e){
            CountriesListElementRoot = createRoot(CountriesListElement.current);
            CountriesListElementRoot.render(countriesResult);
        }
    }

    const FloatingWindow = (props) => {
    
        return (
            <div 
                ref={FloatingWindowRef} dir={props.languageCode === 'ar' ? 'rtl' : 'ltr'} 
                id="dropdown" className="z-10 mt-2 bg-white border rounded-lg shadow-lg hidden" 
                style={{width: "95%", }}
            >
                {/* <!-- Search Field --> */}
                <div className="flex flex-row p-2 border-b">
                    <img 
                        src="static/images/icons/search.svg"
                        width={22} height={22} alt="country"
                        className='ml-1 pt-1'
                    />
                    <input
                        type="text"
                        placeholder={getLanguages()[props.languageCode]["searchForCountry"]}
                        className="w-full outline-none pt-1 ml-3 text-[18px] "
                        style={{
                            color: "#64748B",
                            paddingLeft: props.languageCode === 'en'? '8px' : '0px',
                            paddingRight: props.languageCode === 'en'? '0px' : '8px'
                        }}
                        onChange={(e)=>{
                            getCountriesList(e.target.value);
                        }}
                    />          
                    {/* <!-- Add more countries as needed --> */}
                </div>

                {/* <!-- List of Items --> */}
                <ul ref={CountriesListElement} className="divide-y max-h-80 overflow-y-auto">
                </ul>
            </div>
        )
    }

    useEffect(() => {
        if(countriesListFlag === true){
            CountriesListElementRoot = createRoot(CountriesListElement.current);
            countriesListFlag = false;
            getCountriesList();
        }
    }, []);

 
 
    return (
        <>
            <div 
                ref={PhoneOuterDiv}
                className="flex border border-gray-300 rounded-lg w-full h-12  mt-5 "
            >
                
                <div className="flex flex-row items-center w-full h-full">
                    <div id='countryCode' ref={CountryCodeBtnRef} onClick={()=>{
                        toggleFloatingWindow();
                    }} className="w-[36%] flex justify-center h-full border-r rounded-bl-lg rounded-tl-lg border-gray-300 p-4 text-white transition transform active:scale-95">
                        
                        <div className="w-full h-full flex justify-center items-center ">
                            <div className="w-[30%] flex h-full justify-center items-center">
                                <img 
                                    ref={FlagIconElement}
                                    src="static/images/icons/flags/Qatar.svg" 
                                    className="shadow-[0_4px_6px_rgba(0,0,0,0.16)]"
                                    width={24} height={24} alt="country"
                                />
                            </div>
                            <div className='w-[23%] justify-center items-center'>
                                <img 
                                    ref={DropIconElement}
                                    src="static/images/icons/dropdown.svg" 
                                    width={24} height={24} alt="country" 
                                />
                            </div>
                            <p ref={CountryCodeElement} className="w-[47%] text-[#27364B] text-[18px] leading-[24px] text-center justify-center align-middle">
                                +974
                            </p>
                        </div>
                    </div>
                    <div className="w-[64%] h-10 flex justify-left items-center ml-5 ">
                        <input
                            autoFocus={true}
                            placeholder='1234 5678'
                            type="number"
                            pattern="[0-9]*"
                            ref={PhoneNumberInputElement}
                            onChange={
                                (e)=> {
                                    PhoneNumberInputElement.current.value  = e.target.value.replace(/[^\d]/g, '');
                                    props.onChange(`
                                        ${
                                            CountryCodeElement && CountryCodeElement.current ? 
                                                '+' + (CountryCodeElement.current.innerHTML + e.target.value).replace(/[^\d]/g, '')
                                            :
                                                '+' + (e.target.value).replace(/[^\d]/g, '')
                                        }
                                    `);
                                }
                            }
                            className= 'w-[90%] text-[#27364B] text-[17px] h-full outline-none'
                        />
                    </div>
                </div>
            </div>

            <div ref={errorMarkDiv} className='hidden'>
            { 
                props.languageCode === 'en' ?
                    <div dir="ltr" className='flex flex-row items-center mt-[5px]'>
                        <img src="static/images/icons/error.svg" width={14} height={14} />
                        <span className="text-errorColor-200 text-[12px] text-medimum ml-[6px]">
                            {getLanguages()[props.languageCode]["wrongPhone"]}
                        </span>
                    </div>
                :
                    <div dir="rtl" className='flex flex-row items-center mt-[5px]'>
                        <img src="static/images/icons/error.svg" width={14} height={14} />
                        <span className="text-errorColor-200 text-[12px] text-medimum mr-[6px]">
                            {getLanguages()[props.languageCode]["wrongPhone"]}
                        </span>
                    </div>
            }
            </div>
            
            <FloatingWindow languageCode={props.languageCode} />
        </>
    )
});

export default PhoneNumber;
