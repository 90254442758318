import React, { useRef, useState } from "react";
import mainStyles from "../Stylesheets/main";
import { message} from "antd";
import { getLanguages, convertToArabicNumerals } from "../globals";
import { AppleFilled } from '@ant-design/icons';
import PhoneNumber from "./PhoneNumber";
import TopUpCard from "./TopUpCard";


const DataForm = (props) => {

    const searchParams      = new URLSearchParams(window.location.search);

    const [amount, setAmount]                                     = useState("");
    const [phoneNumber, setPhoneNumber]                           = useState(props.phoneNumber);
    const [email, setEmail]                                       = useState(props.email);
    const [isAmountFocused, setAmountFocused]                     = useState(false);
    // const [isLoadingIndicator, setLoadingIndicator]               = useState(false);
    
    const PhoneNumberRef                                          = useRef(null);

    const urlParameters     = {
        mid: searchParams.get('mid'),
        slotinfo: searchParams.get('slotinfo'),
        sid: searchParams.get('sid'),
        pid: searchParams.get('pid'),
        pri: searchParams.get('pri'),
        iddevice: searchParams.get('iddevice')
    }
    
    const initiatePayment = async (isApplePay=false) => {
        try{
            if(isApplePay) {
                
                props.handleApplePayButton(props.firstName, props.lastName, `${phoneNumber.replace(/[^\d]/g, '')}@vendexintlco.com`, phoneNumber, amount, urlParameters);
                // const paymentID     = result.id;
                // const paymentAmount = result.amount;
                // if(isApplePay === true) {
                //     // setTimeout(() => {setLoadingIndicator(false);}, 3000);
                //     // props.setPayUrl(result.payUrl);
                // }
                return;

            }else{

                await props.getPaymentId(
                    props.firstName, props.lastName, `${phoneNumber.replace(/[^\d]/g, '')}@vendexintlco.com`, 
                    phoneNumber, amount, urlParameters
                ).then((result) => {
                    
                    if(result === "cannot_identify_the_machine"){
                        message.error(getLanguages()[props.languageCode]["please_scan_the_qrcode_on_the_machine"], 5);
                        props.setLoadingIndicator(false);
                        return;
                    }
    
                    if(result === undefined || result === null){
                        props.setLoadingIndicator(false);
                        message.error(getLanguages()[props.languageCode]["failed_to_initiate_payment"], 5);
                        return;
                    }    
        
                    window.location.href = result.payUrl;
                    props.setLoadingIndicator(false);
                    return;
                    // props.setAppleVPNTooleTipVisibility(true);
                    // props.setPayOptionsModal(true);
                    // props.setPaymentIdState(result["id"]);
                    // props.setPayUrl(result["payUrl"]);
                });
            }
        }catch(e){
            props.setLoadingIndicator(false);
            message.error(getLanguages()[props.languageCode]["failed_to_initiate_payment"], 5);
            // console.log(e);
        }
    }

    const createPayment = (isApplePay=false) => {
        
        try{
            if(phoneNumber.length < 8){
                showPhoneNumberError();
                message.error(getLanguages()[props.languageCode]["wrongPhone"]);
                return;
            }
        }catch(error){
            showPhoneNumberError();
            message.error(getLanguages()[props.languageCode]["wrongPhone"]);
            return;
        }

        if(amount.length < 1) {
            message.error(getLanguages()[props.languageCode]["please_enter_amount"]);
            return;
        }

        try{
            const tempAmount = parseFloat(amount);
            if(tempAmount < 1){
                message.error(getLanguages()[props.languageCode]["please_enter_amount"]);
                return;
            } else if(amount > 60000){
                message.error(getLanguages()[props.languageCode]["exceedingAmountLimit"]);
                return;
            }
        }catch(e){
            message.error(getLanguages()[props.languageCode]["please_enter_amount"]);
            return;
        }
        

        if(isApplePay == false){
            props.setLoadingIndicator(true);
        }
   
        initiatePayment(isApplePay);
    }


    const getActiveAmountColor = () => {
        if(isAmountFocused.type === "focus") return "#A87F2E";
        return "#D9D9D9";
    }

    const showPhoneNumberError = () => {
        if(PhoneNumberRef && PhoneNumberRef.current){
            PhoneNumberRef.current.showPhoneNumberError();
        }
    }
    const hidePhoneNumberError = () => {
        if(PhoneNumberRef && PhoneNumberRef.current){
            PhoneNumberRef.current.hidePhoneNumberError();
        }
    }

    return (
        <div className="flex flex-1 flex-col items-center mt-5">

            <div className="flex flex-col">
                <p className="text-[#A87F2E] text-[18px] font-medium self-center">
                    {getLanguages()[props.languageCode]["payAmount"]}
                </p>

                <div dir={props.languageCode === "en" ? "rtl" : "ltr"} className="flex flex-row -ml-6">

                    {
                        props.languageCode === "en" ? 
                            <p className="text-[#A87F2E] text-[20px] font-semibold mt-[10px]">
                                {getLanguages()[props.languageCode]["currency"]}
                            </p>
                        : 
                            <p className="text-[#A87F2E] text-[20px] font-semibold mt-[20px]">
                                {getLanguages()[props.languageCode]["currency"]}
                            </p>
                    }
                    
                    <div style={{display: "flex", flexDirection: "column", width: 95, height: 54}}>
                        {
                            props.languageCode === "ar" ?
                                <input
                                    style={{
                                        ...mainStyles.arabicAmountInput,
                                        borderColor: getActiveAmountColor()
                                    }}
                                    className="placeholder-placeholderColor-100 pb-0 mb-0 leading-tight"
                                    type="number"
                                    pattern="[0-9]*"
                                    min={1}
                                    dir="ltr"
                                    onChange={(e)=>{setAmount(e.target.value);}}
                                    value={amount}
                                    onFocus={setAmountFocused}
                                    onBlur={setAmountFocused}
                                    placeholder={getLanguages()[props.languageCode]["00"]} 
                                />
                            :
                                <input 
                                    style={{
                                        ...mainStyles.englishAmountInput,
                                        borderColor: getActiveAmountColor()
                                    }}
                                    className="placeholder-placeholderColor-100 pb-0 mb-0 leading-tight"
                                    type="number"
                                    pattern="[0-9]*"
                                    min={1}
                                    dir="ltr"
                                    onChange={(e)=>{setAmount(e.target.value);}}
                                    value={amount}
                                    onFocus={setAmountFocused}
                                    onBlur={setAmountFocused}
                                    placeholder={getLanguages()[props.languageCode]["00"]} 
                                />
                        }
                    </div>
                </div>
            </div>

            <div style={{width: "95%", marginTop: 24}}>
                <PhoneNumber 
                    ref={PhoneNumberRef}
                    onChange={
                        (value)=>{
                            hidePhoneNumberError(),
                            setPhoneNumber(`${value}`.trim().replace(/\s+/g, ""));
                        }
                    } 
                    languageCode={props.languageCode}
                />
            </div>
            
            {
                props.languageCode === "en"?
                    <div style={{width: "95%", marginTop: 24}} className="grid grid-cols-2 gap-4">
                        <TopUpCard languageCode={props.languageCode} amount={5} onPress={setAmount} />
                        <TopUpCard languageCode={props.languageCode} amount={10} onPress={setAmount} />
                        <TopUpCard languageCode={props.languageCode} amount={15} onPress={setAmount} />
                        <TopUpCard languageCode={props.languageCode} amount={20} onPress={setAmount} />
                    </div>
                :
                    <div style={{width: "95%", marginTop: 24}} className="grid grid-cols-2 gap-4">
                        <TopUpCard languageCode={props.languageCode} amount={10} onPress={setAmount} />
                        <TopUpCard languageCode={props.languageCode} amount={5} onPress={setAmount} />
                        <TopUpCard languageCode={props.languageCode} amount={20} onPress={setAmount} />
                        <TopUpCard languageCode={props.languageCode} amount={15} onPress={setAmount} />
                    </div>
            }

            
            {
                props.ApplePayAvailable === null ?
                    <div 
                        dir={props.languageCode === "ar" ? "rtl" : "ltr"} 
                        className="w-[90%] flex flex-col items-start">
                        <button
                            className="w-full h-[50px] relative text-gray-700 font-semibold rounded-lg py-2 px-6 mt-[24px] flex items-center justify-center overflow-hidden cursor-not-allowed"
                            disabled
                            >
                            <span className="absolute inset-0 bg-gradient-to-r from-gray-100 via-gray-200 to-placeholderColor-100 animate-pulse"></span>
                        </button>
                        <div
                            className="w-[53%] h-[26px] relative rounded-lg py-2 px-6 mt-[8px] flex items-center justify-center overflow-hidden cursor-not-allowed"
                            disabled
                            >
                            <span className="absolute inset-0 bg-gradient-to-r from-gray-100 via-gray-200 to-placeholderColor-100 animate-pulse"></span>
                        </div>
                    </div>
                :
                    props.ApplePayAvailable === false ?
                        <div 
                            dir={props.languageCode === "ar" ? "rtl" : "ltr"}
                            className="w-[90%] flex flex-col items-start"
                        >
                            <button 
                                dir={props.languageCode === "ar" ? "rtl" : "ltr"}
                                className="w-full h-[50px] relative text-white bg-black bg-opacity-30 font-semibold rounded-lg mt-[24px] flex flex-row justify-center items-center  overflow-hidden cursor-not-allowed"
                                disabled
                            >   
                                <span className="ml-1 mr-1 text-[17px] font-normal">
                                    {getLanguages()[props.languageCode]["payWithApplePay"]}
                                </span>
                                <span className="text-[19px] font-medium">
                                    <AppleFilled color={"white"} />
                                    {getLanguages()[props.languageCode]["pay"]}
                                </span>
                            </button>
                            <div
                                className="h-[28px] bg-skipcash-10 relative rounded-md py-2 px-6 mt-[8px] flex flex-row overflow-hidden"
                            >
                                <img src={"static/images/icons/info.svg"} width={20} height={20} />
                                <span className="font-medium text-[11px] text-start text-skipcash-100">
                                    {getLanguages()[props.languageCode]["noApplePay"]}
                                </span>
                            </div>
                        </div>
                    :
                        <div 
                            dir={props.languageCode === "ar" ? "rtl" : "ltr"}
                            className="w-[90%] flex flex-col items-start"
                        >
                            <button
                                dir={props.languageCode === "ar" ? "rtl" : "ltr"}
                                className="w-full h-[50px] relative text-white bg-black font-semibold rounded-lg mt-[24px] flex flex-row justify-center items-center overflow-hidden "
                                onClick={()=>{createPayment(true);}}
                            >   
                                <span className="ml-1 mr-1 text-[17px] font-normal">
                                    {getLanguages()[props.languageCode]["payWithApplePay"]}
                                </span>
                                <span className="text-[19px] font-medium">
                                    <AppleFilled color={"white"} />
                                    {getLanguages()[props.languageCode]["pay"]}
                                </span>
                            </button>
                            <div
                                className="h-[28px] bg-skipcash-10 relative rounded-md py-2 px-6 mt-[8px] flex flex-row overflow-hidden"
                            >
                                <img src={"static/images/icons/info.svg"} width={20} height={20} />
                                <span className="font-medium text-[11px] text-start text-skipcash-100">
                                    {getLanguages()[props.languageCode]["VPN"]}
                                </span>
                            </div>
                        </div>
            }


            <div 
                className="flex justify-center w-[90%] h-[50px] mt-[16px] items-center text-[17px] font-normal bg-skipcash-100 rounded-lg py-2 px-6 text-white"
                onClick={
                    ()=>{
                        createPayment();
                    }
                }
            >
                {getLanguages()[props.languageCode]["payByCard"]}
            </div>
           {/* <LoadingIndicator /> */}
        </div>
    )
}

export default DataForm;