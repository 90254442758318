const mainStyles = {
    bodyStyle: {display: "flex", flexDirection: "column", width: "100%", margin: 0, padding: 0,},
    desktopScreen: {
        minWidth: 992,
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        height: "90vh",
        // margin: 0
    },

    desktopContainer: {
        display: "flex",
        flex: 0.5,
        flexDirection: "column",
        // justifyContent: "center",
        backgroundColor: "white",
        // boxShadow: "0 0px 8px rgba(0, 0, 0, 0.15)",
        padding: 10,
        // borderRadius: 18,
    },

    mobileTabletScreens: {
        display: "flex",
        flex: 1,
        // justifyContent: "center",
        // alignItems: "center",
        height: "90vh",
        margin: 0
    },

    mobileTabletsContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        // justifyContent: "center",
        backgroundColor: "white",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: 10,
        // borderRadius: 18
    },

    screen: {
        // marginTop: "7%",
        display: "flex",
        flex: 1,
        width: "100%",
        height: "100%",
        alignSelf: "center",
        // justifyContent: "center",
        // alignItems: "center",
    },


    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "80%",
        // alignSelf: "center",
        // justifyContent: "center",
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: 10,
        borderRadius: 18
    },

    containerHeader: {
        margin: 0,
        padding: 0,
        marginTop: 20, 
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 0,
    },

    containerFooter: {
        // position: "absolute",
        width: "100%",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
    },

    title: {
        fontFamily: "JannahLTRegular",
        color: "#000",
    },

    languageContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        alignContent: "center", 
        marginTop: 15,
        marginRight: 0
    },

    formContainer: {
        // marginTop: -20,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        // justifyContent: "space-around",
        alignItems: "center",
    },

    inputText: {
        textAlign: "center",
        marginTop: 4,
        marginBottom: 4,
        width: "90%"
    },

    select: {
        marginTop: 4,
        marginBottom: 4,
        width: "77%",
        textAlign: "center",
    },

    paymentModalProceedAndPayBtn: {
        marginTop: 30,
        width: "100%",           
        flexDirection: "row",
        justifyContent: "center", 
        alignItems: "center",     
        display: "flex",          
        flexWrap: "nowrap",
    },

    appleBtn: {
        marginTop: 24,
        width: "90%",           
        flexDirection: "row",
        justifyContent: "center", 
        alignItems: "center",     
        display: "flex",          
        flexWrap: "nowrap",
    },

    proceedAndApplePayBtn: {
        marginTop: 24,
        width: "100%",
        fontSize: '17px',
        textAlign: "center",
        color: "white",
    },

    proceedAndPayBtnContent: {
        width: "100%",           
        flexDirection: "row",
        justifyContent: "center", 
        alignItems: "center",     
        display: "flex",          
        flexWrap: "nowrap",       
    },

    applePayBtn: {
        marginTop: 30,
        width: "100%",
        fontSize: '17px',
        backgroundColor: "#000000",
        textAlign: "center",
        color: "white",
    },
    
    spinnerIndicatorBlur: {
        width: "100%",
        height: "100%",
        position: 'absolute',
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 101
    },

    spinnerIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 102
    },
    radioBtn: {
        display: "flex", fontSize: 19, fontFamily: "JannahLTRegular", 
        fontWeight: "bold", padding: '10px 10px', justifyContent: 'center', 
        alignItems: 'center'
    },
    
    arabicAmountInput: {
        fontSize: 54,
        textAlign: "center",
        fontFamily: "Rubik",
        borderTopWidth: '0px',
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        borderBottomWidth: '2px',
        borderColor:  '#D9D9D9',
        color: "#A87F2E",
        fontWeight: "500",
        outline: "none",
    },

    englishAmountInput: {
        fontSize: 54,
        textAlign: "center",
        fontFamily: "Rubik",
        borderTopWidth: '0px',
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        borderBottomWidth: '2px',
        borderColor:  '#D9D9D9',
        color: "#A87F2E",
        fontWeight: "500",
        outline: "none"
    }
};

export default mainStyles;