import React, {useRef, useEffect} from 'react';
import { getLanguages, convertToArabicNumerals } from '../globals';

function TopUpCard(props) {
    
    const btnElement    = useRef(null);
    const btnTopElement = useRef(null);

    const handleClickEffect = (e) => {
        if (!btnElement.current.classList.contains('border')){
            btnElement.current.classList.add('rounded-lg', 'border', 'border-vendexColor-200');
            btnTopElement.current.classList.add('rounded-lg');
        }

        props.onPress(props.amount);
    }
    
    useEffect(() => {

        const handleClickOutside = (event) => {
          if (btnElement.current && !btnElement.current.contains(event.target)) {
            if(btnElement.current.classList.contains('border')){
                btnElement.current.classList.remove('rounded-lg', 'border', 'border-vendexColor-200');
                btnTopElement.current.classList.remove('rounded-lg');
            }
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        // Cleanup the event listener when the component is unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickEffect]);


    return (
        <div ref={btnElement} onClick={handleClickEffect} className="flex flex-col w-full h-[101px]  bg-white transition transform active:scale-95 hover:selection:border hover:selection:border-vendexColor-200">

            <div ref={btnTopElement} className="h-[63%] bg-vendexColor-100">
                {
                    props.languageCode === "en" ?
                        <div dir={'ltr'} className="h-full flex flex-row justify-center items-center">
                            <span className='text-vendexColor-200 text-center text-[36px] font-medium'>
                                {props.amount}
                            </span>
                            <span className='ml-[4px] text-vendexColor-200 text-center text-[16px] font-medium'>
                                {getLanguages()[props.languageCode]["currency"]}
                            </span>
                        </div>
                    :
                        <div dir={'rtl'} className="h-full flex flex-row justify-center items-center">
                            <span className='text-vendexColor-200 text-center text-[36px] font-medium'>
                                {convertToArabicNumerals(`${props.amount}`)}
                            </span>
                            <span className='mr-[4px] text-vendexColor-200 text-center text-[16px] font-medium'>
                                {getLanguages()[props.languageCode]["currency"]}
                            </span>
                        </div>
                }
            </div>
            <div className="h-[37%] bg-vendexColor-200 rounded-b-md border border-vendexColor-200">
                <div className='h-full flex justify-center items-center'>
                    <span className='text-white text-center text-[16px] font-medium'>
                        {
                            props.languageCode === 'en' ?
                                `${getLanguages()[props.languageCode]["topUp"]}`.toUpperCase()
                            :
                                getLanguages()[props.languageCode]["topUp"]
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}

export default TopUpCard;