import React from 'react';
import { Button } from "antd";
import { getLanguages } from "../globals";
import mainStyles from "../Stylesheets/main";


const LangChanger = (props) => {

    const handleChangeLanguage = () => {
        if (props.languageCode === "en"){
            props.setLanguageCode("ar");
            return;
        }
        props.setLanguageCode("en");
    }

    return (
        <div style={mainStyles.languageContainer}> 

            <Button 
                shape="default"
                color={"#A87F2E"}
                style={{display: "flex", width: 125, height: 34, alignItems: "center"}}
                onClick={() =>{ handleChangeLanguage() }}
            >   

                {
                    props.languageCode === "en" ?
                        <div style={{width: "100%", display: "flex",  justifyContent: "space-around"}}>
                            <p style={{color: "#4F4E4E", fontSize: 16, fontWeight: "bold"}}>                  
                                {getLanguages()[props.languageCode]["language"]}
                            </p>
                            <img src="static/images/qatar_flag.svg" width={24} height={24} alt="arabic" style={{alignSelf: "center"}} />
                        </div>
                    :
                        <div style={{width: "100%", display: "flex",  justifyContent: "space-around"}}>
                            <img src="static/images/english_flag.svg" width={24} height={24} alt="english" style={{alignSelf: "center"}} />
                            <p style={{color: "#4F4E4E", fontSize: 16, fontWeight: "bold"}}>                  
                                {getLanguages()[props.languageCode]["language"]}
                            </p>
                        </div>
                }
            </Button>
           
        </div>
    )
}

export default LangChanger;