import React from 'react';
import { Divider } from "antd";
import { getLanguages } from "../globals";
import mainStyles from "../Stylesheets/main";


const Header = (props) => {
    return (
        <div 
            // className="m-0 p-0 mt-5 w-full flex flex-col self-center justify-center items-center gap-0"
            style={mainStyles.containerHeader}
        >
            {/* <img src="images/vendexLogo.jpg" width={130.67} height={135.64} alt="english" /> */}
            <img src="static/images/vendexLogo.gif" width={75} height={76} alt="vendex" />
            {/* <p className="mt-5 text-[18px] font-semibold leading-[21.33px]"> */}
            <p style={{fontSize: 18, fontWeight: "600", lineHeight: "21.33px", marginTop: 10}}>
                {getLanguages()[props.languageCode]["title"]}
            </p>
        </div>
    )
}

export default Header;