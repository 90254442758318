import ar from './translation/ar.json';
import en from './translation/en.json';


// const PROTOCOL  = "https";
// const DOMAIN    = "vendex-payme-39fd6faa99c5.herokuapp.com";

export const SERVER         = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/api/`;

const languages = {
    "en": en,
    "ar": ar
};


export const getLanguages = () => {
    return languages;
}


export const convertToArabicNumerals = (input) => {
    const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return input.replace(/\d/g, (digit) => arabicDigits[digit]);
}


export const countries = {
    "Afghanistan": {icon: "Afghanistan.svg", code: "+93", langCode: {"ar": "أفغانستان", "en": "Afghanistan"}},
    "Åland": {icon: "Aland.svg", code: "+358", langCode: {"ar": "جزر آلاند", "en": "Åland"}},
    "Albania": {icon: "Albania.svg", code: "+355", langCode: {"ar": "ألبانيا", "en": "Albania"}},
    "Algeria": {icon: "Algeria.svg", code: "+213", langCode: {"ar": "الجزائر", "en": "Algeria"}},
    "American Samoa": {icon: "AmericanSamoa.svg", code: "+1 684", langCode: {"ar": "ساموا الأمريكية", "en": "American Samoa"}},
    "Andorra": {icon: "Andorra.svg", code: "+376", langCode: {"ar": "أندورا", "en": "Andorra"}},
    "Angola": {icon: "Angola.svg", code: "+244", langCode: {"ar": "أنغولا", "en": "Angola"}},
    "Anguilla": {icon: "Anguilla.svg", code: "+1 264", langCode: {"ar": "أنغويلا", "en": "Anguilla"}},
    "Antigua and Barbuda": {icon: "AntiguaandBarbuda.svg", code: "+1 268", langCode: {"ar": "أنتيغوا وبربودا", "en": "Antigua and Barbuda"}},
    "Argentina": {icon: "Argentina.svg", code: "+54", langCode: {"ar": "الأرجنتين", "en": "Argentina"}},
    "Armenia": {icon: "Armenia.svg", code: "+374", langCode: {"ar": "أرمينيا", "en": "Armenia"}},
    "Aruba": {icon: "Aruba.svg", code: "+297", langCode: {"ar": "أروبا", "en": "Aruba"}},
    "Ascension": {icon: "Ascension.svg", code: "+247", langCode: {"ar": "جزيرة أسينسيون", "en": "Ascension"}},
    "Australia": {icon: "Australia.svg", code: "+61", langCode: {"ar": "أستراليا", "en": "Australia"}},
    "Austria": {icon: "Austria.svg", code: "+43", langCode: {"ar": "النمسا", "en": "Austria"}},
    "Azerbaijan": {icon: "Azerbaijan.svg", code: "+994", langCode: {"ar": "أذربيجان", "en": "Azerbaijan"}},
    "Bahamas": {icon: "Bahamas.svg", code: "+1 242", langCode: {"ar": "جزر البهاما", "en": "Bahamas"}},
    "Bahrain": {icon: "Bahrain.svg", code: "+973", langCode: {"ar": "البحرين", "en": "Bahrain"}},
    "Bangladesh": {icon: "Bangladesh.svg", code: "+880", langCode: {"ar": "بنغلاديش", "en": "Bangladesh"}},
    "Barbados": {icon: "Barbados.svg", code: "+1 246", langCode: {"ar": "بربادوس", "en": "Barbados"}},
    "Belarus": {icon: "Belarus.svg", code: "+375", langCode: {"ar": "بيلاروسيا", "en": "Belarus"}},
    "Belgium": {icon: "Belgium.svg", code: "+32", langCode: {"ar": "بلجيكا", "en": "Belgium"}},
    "Belize": {icon: "Belize.svg", code: "+501", langCode: {"ar": "بليز", "en": "Belize"}},
    "Benin": {icon: "Benin.svg", code: "+229", langCode: {"ar": "بنين", "en": "Benin"}},
    "Bermuda": {icon: "Bermuda.svg", code: "+1 441", langCode: {"ar": "برمودا", "en": "Bermuda"}},
    "Bhutan": {icon: "Bhutan.svg", code: "+975", langCode: {"ar": "بوتان", "en": "Bhutan"}},
    "Bolivia": {icon: "Bolivia.svg", code: "+591", langCode: {"ar": "بوليفيا", "en": "Bolivia"}},
    "Bonaire": {icon: "Bonaire.svg", code: "+599", langCode: {"ar": "بونير", "en": "Bonaire"}},
    "Bosnia and Herzegovina": {icon: "BosniaandHerzegovina.svg", code: "+387", langCode: {"ar": "البوسنة والهرسك", "en": "Bosnia and Herzegovina"}},
    "Botswana": {icon: "Botswana.svg", code: "+267", langCode: {"ar": "بوتسوانا", "en": "Botswana"}},
    "Brazil": {icon: "Brazil.svg", code: "+55", langCode: {"ar": "البرازيل", "en": "Brazil"}},
    "British Indian Ocean Territory": {icon: "BritishIndianOceanTerritory.svg", code: "+246", langCode: {"ar": "إقليم المحيط الهندي البريطاني", "en": "British Indian Ocean Territory"}},
    "British Virgin Islands": {icon: "BritishVirginIslands.svg", code: "+1 284", langCode: {"ar": "جزر العذراء البريطانية", "en": "British Virgin Islands"}},
    "Brunei Darussalam": {icon: "BruneiDarussalam.svg", code: "+673", langCode: {"ar": "بروناي", "en": "Brunei Darussalam"}},
    "Bulgaria": {icon: "Bulgaria.svg", code: "+359", langCode: {"ar": "بلغاريا", "en": "Bulgaria"}},
    "Burkina Faso": {icon: "BurkinaFaso.svg", code: "+226", langCode: {"ar": "بوركينا فاسو", "en": "Burkina Faso"}},
    "Burundi": {icon: "Burundi.svg", code: "+257", langCode: {"ar": "بوروندي", "en": "Burundi"}},
    "Qatar": {icon: "Qatar.svg", code: "+974", langCode: {"ar": "قطر", "en": "Qatar"}},

    "CaboVerde": {icon: "CaboVerde.svg", code: "+238", langCode: {"ar": "كابو فيردي", "en": "Cabo Verde"}},
    "Cambodia": {icon: "Cambodia.svg", code: "+855", langCode: {"ar": "كمبوديا", "en": "Cambodia"}},
    "Cameroon": {icon: "Cameroon.svg", code: "+237", langCode: {"ar": "الكاميرون", "en": "Cameroon"}},
    "Canada": {icon: "Canada.svg", code: "+1", langCode: {"ar": "كندا", "en": "Canada"}},
    "Netherlands": {icon: "Netherlands.svg", code: "+31", langCode: {"ar": "هولندا", "en": "Netherlands"}},
    "CaymanIslands": {icon: "CaymanIslands.svg", code: "+1 345", langCode: {"ar": "جزر كايمان", "en": "Cayman Islands"}},
    "CentralAfricanRepublic": {icon: "CentralAfricanRepublic.svg", code: "+236", langCode: {"ar": "جمهورية أفريقيا الوسطى", "en": "Central African Republic"}},
    "Chad": {icon: "Chad.svg", code: "+235", langCode: {"ar": "تشاد", "en": "Chad"}},
    "Chile": {icon: "Chile.svg", code: "+56", langCode: {"ar": "تشيلي", "en": "Chile"}},
    "China": {icon: "China.svg", code: "+86", langCode: {"ar": "الصين", "en": "China"}},
    "ChristmasIsland": {icon: "ChristmasIsland.svg", code: "+61", langCode: {"ar": "جزيرة الكريسماس", "en": "Christmas Island"}},
    "CocosKeelingIslands": {icon: "CocosKeelingIslands.svg", code: "+61", langCode: {"ar": "جزر كوكوس", "en": "Cocos (Keeling) Islands"}},
    "Colombia": {icon: "Colombia.svg", code: "+57", langCode: {"ar": "كولومبيا", "en": "Colombia"}},
    "Comoros": {icon: "Comoros.svg", code: "+269", langCode: {"ar": "جزر القمر", "en": "Comoros"}},
    "Congo": {icon: "Congo.svg", code: "+242", langCode: {"ar": "الكونغو", "en": "Congo"}},
    "DemocraticRepublicOfTheCongo": {icon: "DemocraticRepublicOfTheCongo.svg", code: "+243", langCode: {"ar": "جمهورية الكونغو الديمقراطية", "en": "D-Republic of the Congo"}},
    "Côte D'ivoire": {icon: "CoteDivoire.svg", code: "+225", langCode: {"ar": "كوت ديفوار", "en": "Côte d'Ivoire"}},
    "Croatia": {icon: "Croatia.svg", code: "+385", langCode: {"ar": "كرواتيا", "en": "Croatia"}},
    "Curaçao": {icon: "Curacao.svg", code: "+599", langCode: {"ar": "كوراساو", "en": "Curaçao"}},
    "Cyprus": {icon: "Cyprus.svg", code: "+357", langCode: {"ar": "قبرص", "en": "Cyprus"}},
    "CzechRepublic": {icon: "CzechRepublic.svg", code: "+420", langCode: {"ar": "جمهورية التشيك", "en": "Czech Republic"}},
    "Denmark": {icon: "Denmark.svg", code: "+45", langCode: {"ar": "الدنمارك", "en": "Denmark"}},
    "Djibouti": {icon: "Djibouti.svg", code: "+253", langCode: {"ar": "جيبوتي", "en": "Djibouti"}},
    "Dominica": {icon: "Dominica.svg", code: "+1 767", langCode: {"ar": "دومينيكا", "en": "Dominica"}},
    "DominicanRepublic": {icon: "DominicanRepublic.svg", code: "+1 809", langCode: {"ar": "جمهورية الدومينيكان", "en": "Dominican Republic"}},

    "Syria": {icon: "Syria.svg", code: "+963", langCode: {"ar": "سوريا", "en": "Syria"}},
    "SouthSudan": {icon: "SouthSudan.svg", code: "+211", langCode: {"ar": "جنوب السودان", "en": "South Sudan"}},
    "Sudan": {icon: "Sudan.svg", code: "+249", langCode: {"ar": "السودان", "en": "Sudan"}},
    "Somalia": {icon: "Somalia.svg", code: "+252", langCode: {"ar": "الصومال", "en": "Somalia"}},
    "SouthAfrica": {icon: "SouthAfrica.svg", code: "+27", langCode: {"ar": "جنوب أفريقيا", "en": "South Africa"}},
    "SvalbardAndJanMayen": {icon: "SvalbardAndJanMayen.svg", code: "+47", langCode: {"ar": "سفالبارد ويان ماين", "en": "Svalbard and Jan Mayen"}},
    "Singapore": {icon: "Singapore.svg", code: "+65", langCode: {"ar": "سنغافورة", "en": "Singapore"}},
    "Switzerland": {icon: "Switzerland.svg", code: "+41", langCode: {"ar": "سويسرا", "en": "Switzerland"}},
    "Spain": {icon: "Spain.svg", code: "+34", langCode: {"ar": "إسبانيا", "en": "Spain"}},
    "Thailand": {icon: "Thailand.svg", code: "+66", langCode: {"ar": "تايلاند", "en": "Thailand"}},
    "Tajikstan": {icon: "Tajikstan.svg", code: "+992", langCode: {"ar": "طاجيكستان", "en": "Tajikistan"}},
    "Turkey": {icon: "Turkey.svg", code: "+90", langCode: {"ar": "تركيا", "en": "Turkey"}},
    "Tunisia": {icon: "Tunisia.svg", code: "+216", langCode: {"ar": "تونس", "en": "Tunisia"}},
    "Taiwan": {icon: "Taiwan.svg", code: "+886", langCode: {"ar": "تايوان", "en": "Taiwan"}},
    "uae": {icon: "uae.svg", code: "+971", langCode: {"ar": "الإمارات", "en": "UAE"}},
    "Uzbekistan": {icon: "Uzbekistan.svg", code: "+998", langCode: {"ar": "أوزبكستان", "en": "Uzbekistan"}},
    "Vietnam": {icon: "Vietnam.svg", code: "+84", langCode: {"ar": "فيتنام", "en": "Vietnam"}},
    "WallisAndFutuna": {icon: "WallisAndFutuna.svg", code: "+681", langCode: {"ar": "واليس وفوتونا", "en": "Wallis and Futuna"}},
    "Yemen": {icon: "Yemen.svg", code: "+967", langCode: {"ar": "اليمن", "en": "Yemen"}},
    "UnitedKingdom": {icon: "UnitedKingdom.svg", code: "+44", langCode: {"ar": "المملكة المتحدة", "en": "United Kingdom"}},
    "USA": {icon: "USA.svg", code: "+1", langCode: {"ar": "الولايات المتحدة", "en": "USA"}},
    "France": {icon: "France.svg", code: "+33", langCode: {"ar": "فرنسا", "en": "France"}},
    "Portugal": {icon: "Portugal.svg", code: "+351", langCode: {"ar": "البرتغال", "en": "Portugal"}},
    "Poland": {icon: "Poland.svg", code: "+48", langCode: {"ar": "بولندا", "en": "Poland"}},
    "PuertoRico": {icon: "PuertoRico.svg", code: "+1", langCode: {"ar": "بورتوريكو", "en": "Puerto Rico"}},
    "Pakistan": {icon: "Pakistan.svg", code: "+92", langCode: {"ar": "باكستان", "en": "Pakistan"}},
    "Philippines": {icon: "Philippines.svg", code: "+63", langCode: {"ar": "الفلبين", "en": "Philippines"}},
    "Peru": {icon: "Peru.svg", code: "+51", langCode: {"ar": "بيرو", "en": "Peru"}},
    "Paraguay": {icon: "Paraguay.svg", code: "+595", langCode: {"ar": "باراغواي", "en": "Paraguay"}},
    "Panama": {icon: "Panama.svg", code: "+507", langCode: {"ar": "بنما", "en": "Panama"}},
    "Oman": {icon: "Oman.svg", code: "+968", langCode: {"ar": "عمان", "en": "Oman"}},
    "Norway": {icon: "Norway.svg", code: "+47", langCode: {"ar": "النرويج", "en": "Norway"}},
    "Niger": {icon: "Niger.svg", code: "+227", langCode: {"ar": "النيجر", "en": "Niger"}},
    "Nigeria": {icon: "Nigeria.svg", code: "+234", langCode: {"ar": "نيجيريا", "en": "Nigeria"}},
    "NorthernCyprus": {icon: "NorthernCyprus.svg", code: "+90", langCode: {"ar": "شمال قبرص", "en": "Northern Cyprus"}},
    "NewZealand": {icon: "NewZealand.svg", code: "+64", langCode: {"ar": "نيوزيلندا", "en": "New Zealand"}},
    "Morocco": {icon: "Morocco.svg", code: "+212", langCode: {"ar": "المغرب", "en": "Morocco"}},
    "Myanmar": {icon: "Myanmar.svg", code: "+95", langCode: {"ar": "ميانمار", "en": "Myanmar"}},
    "Mauritania": {icon: "Mauritania.svg", code: "+222", langCode: {"ar": "موريتانيا", "en": "Mauritania"}},
    "Mexico": {icon: "Mexico.svg", code: "+52", langCode: {"ar": "المكسيك", "en": "Mexico"}},
    "Monaco": {icon: "Monaco.svg", code: "+377", langCode: {"ar": "موناكو", "en": "Monaco"}},
    "Malaysia": {icon: "Malaysia.svg", code: "+60", langCode: {"ar": "ماليزيا", "en": "Malaysia"}},
    "Mali": {icon: "Mali.svg", code: "+223", langCode: {"ar": "مالي", "en": "Mali"}},
    "Luxembourg": {icon: "Luxembourg.svg", code: "+352", langCode: {"ar": "لوكسمبورغ", "en": "Luxembourg"}},
    "Laos": {icon: "Laos.svg", code: "+856", langCode: {"ar": "لاوس", "en": "Laos"}},
    "Latvia": {icon: "Latvia.svg", code: "+371", langCode: {"ar": "لاتفيا", "en": "Latvia"}},
    "Liberia": {icon: "Liberia.svg", code: "+231", langCode: {"ar": "ليبيريا", "en": "Liberia"}},
    "Lebanon": {icon: "Lebanon.svg", code: "+961", langCode: {"ar": "لبنان", "en": "Lebanon"}},
    "Libya": {icon: "Libya.svg", code: "+218", langCode: {"ar": "ليبيا", "en": "Libya"}},
    "SouthKorea": {icon: "SouthKorea.svg", code: "+82", langCode: {"ar": "كوريا الجنوبية", "en": "South Korea"}},
    "Kuwait": {icon: "Kuwait.svg", code: "+965", langCode: {"ar": "الكويت", "en": "Kuwait"}},
    "NorthKorea": {icon: "NorthKorea.svg", code: "+850", langCode: {"ar": "كوريا الشمالية", "en": "North Korea"}},
    "Jordan": {icon: "Jordan.svg", code: "+962", langCode: {"ar": "الأردن", "en": "Jordan"}},
    "Japan": {icon: "Japan.svg", code: "+81", langCode: {"ar": "اليابان", "en": "Japan"}},
    "Jamaica": {icon: "Jamaica.svg", code: "+1", langCode: {"ar": "جامايكا", "en": "Jamaica"}},
    "Italy": {icon: "Italy.svg", code: "+39", langCode: {"ar": "إيطاليا", "en": "Italy"}},
    "Ireland": {icon: "Ireland.svg", code: "+353", langCode: {"ar": "أيرلندا", "en": "Ireland"}},
    "Indonesia": {icon: "Indonesia.svg", code: "+62", langCode: {"ar": "إندونيسيا", "en": "Indonesia"}},
    "Iran": {icon: "Iran.svg", code: "+98", langCode: {"ar": "إيران", "en": "Iran"}},
    "Iraq": {icon: "Iraq.svg", code: "+964", langCode: {"ar": "العراق", "en": "Iraq"}},
    "India": {icon: "India.svg", code: "+91", langCode: {"ar": "الهند", "en": "India"}},
    "Hungary": {icon: "Hungary.svg", code: "+36", langCode: {"ar": "المجر", "en": "Hungary"}},
    "HongKong": {icon: "HongKong.svg", code: "+852", langCode: {"ar": "هونغ كونغ", "en": "Hong Kong"}},
    "Guinea": {icon: "Guinea.svg", code: "+224", langCode: {"ar": "غينيا", "en": "Guinea"}},
    "Germany": {icon: "Germany.svg", code: "+49", langCode: {"ar": "ألمانيا", "en": "Germany"}},
    "Georgia": {icon: "Georgia.svg", code: "+995", langCode: {"ar": "جورجيا", "en": "Georgia"}},
    "Ghana": {icon: "Ghana.svg", code: "+233", langCode: {"ar": "غانا", "en": "Ghana"}},
    "Greece": {icon: "Greece.svg", code: "+30", langCode: {"ar": "اليونان", "en": "Greece"}},
    "Finland": {icon: "Finland.svg", code: "+358", langCode: {"ar": "فنلندا", "en": "Finland"}},
    "Ethiopia": {icon: "Ethiopia.svg", code: "+251", langCode: {"ar": "إثيوبيا", "en": "Ethiopia"}},
    "Egypt": {icon: "Egypt.svg", code: "+20", langCode: {"ar": "مصر", "en": "Egypt"}},
    "SaudiArabia": {icon: "SaudiArabia.svg", code: "+966", langCode: {"ar": "السعودية", "en": "Saudi Arabia"}},
    "Ukraine": {icon: "Ukraine.svg", code: "+380", langCode: {"ar": "أوكرانيا", "en": "Ukraine"}},
    "Palestine": {icon: "Palestine.svg", code: "+970", langCode: {"ar": "فلسطين", "en": "Palestine"}},
    "Russia": {icon: "Russia.svg", code: "+7", langCode: {"ar": "روسيا", "en": "Russia"}},
    "Nepal": {icon: "Nepal.svg", code: "+977", langCode: {"ar": "نيبال", "en": "Nepal"}},
    "Eritrea": {icon: "Eritrea.svg", code: "+291", langCode: {"ar": "إريتريا", "en": "Eritrea"}}
  };

// "Algeria": {icon: "Algeria.svg", code: ""},    